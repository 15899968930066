#modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    text-indent: 100;
}
.modal {
    width: 500px;
    background: var(--clr-bg-inv-2);
    border: 1px solid var(--clr-bg);
    transition: 1.1s ease-out;
    box-shadow: -2rem 2rem 2rem rgba(0, 0, 0, 0.2);
    filter: blur(0);
    transform: scale(1);
    opacity: 1;
    visibility: visible;
  }
  .modal.modal-wide {
    width: 90%;
    max-width: 800px;
  }


  .modal h2 {
    border-bottom: 1px solid var(--clr-bg-alt);
    padding: 1rem;
    margin: 0;
    font-size: 1.25rem;
  }
  .modal .content {
    padding: 1rem;
  }
  .modal .actions {
    border-top: 1px solid var(--clr-bg-alt);
    background: var(--clr-bg-inv-2);
    padding: 0.5rem 1rem;
    text-align: center;
  }
  .modal .actions button {
    border: 0;
    background: var(--clr-bg);
    border-radius: 5px;
    padding: 0.5rem 1rem;
    font-size: 1rem;
    line-height: 1;
  }

  .modal code {
    color: var(--clr-primary);
  }
  
  #centered-toggle-button {
    position: absolute;
  }

  .modal ul {
    padding-left: 1rem;
    margin-bottom: 0.5rem;
  }

  .modal p {
    margin-bottom: 0.5rem;
  }

  .modal p.mt {
    margin-top: 1rem;
  }

  .modal a {
    color: var(--clr-primary);
  }