.connected-wallet {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 200px;
    display: inline-block;
    background-color: var(--clr-bg);
    padding: .5rem;
}

.disconnect-button {
    padding: .5rem;
}