.app {
  font-family: 'Poppins', sans-serif;
  line-height: 1.5;
  color: var(--clr-fg);
  max-width: 1200px;
  margin: 0 auto;
  height: 100%;
}

.container {
  background-color: var(--clr-bg);
  padding: 2rem;
  border-radius: 5px;
}

h4.text-center {
  text-align: center;
  margin-bottom: 0.5rem;
}

.text-right {
  text-align: right;
}

.light {
  --clr-bg: rgba(255, 255, 255, 0.9);
  --clr-bg-inv: rgba(0, 0, 0, 0.04);
  --clr-bg-inv-2: rgba(214, 214, 214, 0.95);
  --clr-bg-alt: rgb(255, 255, 255);
  --clr-fg: #555;
  --clr-fg-alt: #444;
  --clr-primary: #2978b5;
  --shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.dark {
  --clr-bg: rgba(0, 0, 0, 0.4);
  --clr-bg-inv: rgba(255, 255, 255, 0.1);
  --clr-bg-inv-2: rgba(20, 20, 20, 0.95);
  --clr-bg-alt: rgb(0, 0, 0);
  --clr-fg: #bdbddd;
  --clr-fg-alt: #cdcdff;
  --clr-primary: #90a0d9;
  --shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
}

main {
  max-width: 1100px;
  width: 95%;
  margin: 0 auto;
}

.section {
  margin-top: 5em;
}

.section__title {
  text-align: center;
  margin-bottom: 1em;
  text-transform: uppercase;
}

.center {
  display: flex;
  align-items: center;
}

.link {
  color: var(--clr-primary);
  padding: 0 0 0.3em 0;
  position: relative;
}

.link:hover {
  color: var(--clr-primary);
}

.link::before {
  content: '';
  display: inline;
  width: 0%;
  height: 0.2em;
  position: absolute;
  bottom: 0;
  background-color: var(--clr-primary);
  transition: width 0.2s ease-in;
}

.link:hover::before {
  width: 100%;
}

.link--nav {
  color: var(--clr-fg);
  text-transform: lowercase;
  font-weight: 500;
}

.link--icon {
  color: var(--clr-fg);
}

.btn {
  display: block;
  cursor: pointer;
  padding: 0.8em 1.4em;
  font-weight: 500;
  font-size: 0.9rem;
  text-transform: lowercase;
  transition: transform 0.2s ease-in-out;
  background-color: var(--clr-bg-alt);
}

.btn--outline {
  color: var(--clr-primary);
  border: 2px solid var(--clr-primary);
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.btn--outline:hover {
  color: var(--clr-bg);
}

.btn--outline:before {
  content: '';
  position: absolute;
  background-color: var(--clr-primary);
  right: 100%;
  bottom: 0;
  left: 0;
  top: 0;
  z-index: -1;
  transition: right 0.2s ease-in-out;
}

.btn--outline:hover:before {
  right: 0;
}

.btn--plain {
  text-transform: initial;
  background-color: var(--clr-bg-alt);
  box-shadow: rgba(0, 0, 0, 0.15) 0px 3px 3px 0px;
  border: 0;
}

.btn--plain:hover {
  transform: translateY(-4px);
}

.btn--icon {
  padding: 0;
}

.btn--icon:hover {
  color: var(--clr-primary);
}

.btn--icon:active {
  transform: translateY(-5px);
}

@media (max-width: 600px) {
  .section {
    margin-top: 4em;
  }
}

#network-banner,
#network-banner-warn {
  text-align: center;
  padding: 0.25rem;
  background-color: #2978b5;
  color: white;
}

#network-banner-warn {
  background-color: #da5c14;
}

element.style {
  background-color: rgba(0,0,0,0.2);
  height: 1px;
  width: 90%;
  margin: 4rem auto 2rem;
}

.burn-input-container {
  display: flex;
  margin-bottom: 3rem;
  justify-content: center;
  align-items: stretch;
  flex-wrap: wrap;
}

.burn-input-container > input {
  padding: 0.25rem 0.5rem;
  text-align: center;
  background-color: var(--clr-bg-alt);
  color: var(--clr-fg-alt);
}

.burn-input-container > button {
  text-transform: uppercase;
  border-left: solid 1px var(--clr-primary);
}

.burn-input-percentage, .send-numbers {
  width: 100%;
  margin-top: .5rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;
}

select {
  padding: 0.5rem 1rem;
}

.burn-input-percentage

.not-connected {
  margin-top: 2rem;
  text-align: center;
}

.donate-button {
  position: fixed;
  font-size: 0.875rem;
  padding: 0.5rem 1.5rem;
  text-decoration: none;
  border-radius: 50rem !important;
  background-color: rgba(255, 193, 7, 0.9) !important;
  color: rgb(33, 37, 41) !important;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 8px 16px 0px !important;
}

.donate-button:hover {
  background-color: rgb(255, 193, 7) !important;
}