.header {
  height: 8em;
  max-width: 1100px;
  width: 95%;
  margin: 0 auto;
  justify-content: space-between;
}

#logo {
  display: inline-block;
  max-width: 500px;
}

#logo > img {
  width: 100%;
}

.header > nav button {
  background: none;
  color: whitesmoke;
  margin-left: 1rem;
}

.header > nav > div {
  margin-left: 1rem;
}

.header > nav > div > div {
  display: flex;
  justify-content: end;
}

.header > nav > div > div > span {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.header > nav > div > div > button {
  margin-left: 0;
  display: inline-block;
  background-color: var(--clr-bg);
  color: var(--clr-fg);
  padding: .5rem;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

@media (max-width: 800px) {
  .header {
    flex-wrap: wrap;
    height: auto;
    justify-content: center;
  }
  .header h3 {
    flex-basis: calc(100% - 40px);
    flex-shrink: 0;
  }
  .header .nav.center {
    justify-content: center;
  }
}

/*@media (max-width: 600px) {
  .header {
    height: 6em;
  }
}
*/